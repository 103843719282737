tag = document.createElement('script')
player = undefined


onYouTubeIframeAPIReady = ->
  console.log('yoow');
  $('.video-hero').hide()
  vid = document.getElementById('playerid')
  video_id = vid.getAttribute('data-vid')
  player = new (YT.Player)('player',
    videoId: video_id
    playerVars:
      'autoplay': 0
      'rel': 0
      'modestbranding': 1
      'controls': 0
      'showinfo': 0
    events: 'onStateChange': onPlayerStateChange)
  console.log(player)
  return

onPlayerStateChange = (event) ->
  if event.data == YT.PlayerState.ENDED
    $('.video-hero').fadeOut()
  if event.data == YT.PlayerState.PAUSED
    $('.video-hero').fadeOut()
  return

tag.src = 'https://www.youtube.com/iframe_api'
firstScriptTag = document.getElementsByTagName('script')[0]
firstScriptTag.parentNode.insertBefore tag, firstScriptTag


$(document).ready ->
  $.stellar(
    hideDistantElements: true
    responsive: true
  )
  # rellax = new Rellax('.rellax',
  #   center: true
  # )

  # rellaxTop = new Rellax('.parallax-top',
  #   center: true
  # )   
  console.log(player)
  (new WOW).init()
  $('.playnow').on 'click', (e) ->
    e.preventDefault()
    vid = $(this).data('vid')
    $('#playerid').data 'vid', vid
    console.log(player)
    player.loadVideoById
      videoId: vid
      playerVars:
        'autoplay': 0
        'rel': 0
        'modestbranding': 1
        'controls': 0
        'showinfo': 0
      events: 'onStateChange': onPlayerStateChange
    $('.video-hero').show()
    player.playVideo()
    return

  $(window).scroll ->
    $('#progress').text $(window).scrollTop()
    if $(window).scrollTop() > screen.height
      $('.video-hero').fadeOut()
      if typeof player == 'object'
        player.pauseVideo()
    return
  
  $('.video-close').on 'click', (e) ->
    e.preventDefault()
    $('.video-hero').fadeOut()
    player.stopVideo()
    return
  
  
  $('#comment-gallery').owlCarousel
    items: 2
    itemsDesktop: [
      1199
      2
    ]
    itemsDesktopSmall: [
      980
      2
    ]
    itemsTablet: [
      768
      2
    ]
    itemsTabletSmall: false
    itemsMobile: [
      479
      1
    ]
    singleItem: false
    slideSpeed: 200
    paginationSpeed: 800
    rewindSpeed: 1000
    autoPlay: false
    stopOnHover: true
    navigation: false
    navigationText: [
      'prev'
      'next'
    ]
    rewindNav: true
    scrollPerPage: false
    pagination: true
    paginationNumbers: false
    responsive: true
    responsiveRefreshRate: 200
    responsiveBaseWidth: window
    lazyLoad: false
    lazyFollow: true
    autoHeight: false


    
  owl = $('#video-gallery')
  owl.owlCarousel
    items: 3
    itemsDesktop: [
      1199
      3
    ]
    itemsDesktopSmall: [
      980
      3
    ]
    itemsTablet: [
      768
      2
    ]
    itemsTabletSmall: false
    itemsMobile: [
      479
      1
    ]
    singleItem: false
    slideSpeed: 200
    paginationSpeed: 800
    rewindSpeed: 1000
    autoPlay: false
    stopOnHover: true
    navigation: false
    navigationText: [
      'prev'
      'next'
    ]
    rewindNav: true
    scrollPerPage: false
    pagination: true
    paginationNumbers: false
    responsive: true
    responsiveRefreshRate: 200
    responsiveBaseWidth: window
    lazyLoad: false
    lazyFollow: true
    autoHeight: false


 